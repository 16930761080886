<template>
    <div class="diagnostic modal" id="objectDiag">
        <div class="modal__wrapper modal__wrapper_full">
            <div class="modal__header">
                <div class="diagnostic__headerTitle">Результаты диагностики</div>
            </div>
            <div class="modal__descr" v-if="objectDiag">Объект: {{ objectName }}</div>
            <span class="modal__close diagnostic__close" @click="showModal($event, false)" data-modal="objectDiag"></span>
            <div class="diagnostic__body modal__body">
                <div class="diagnostic__list" v-if="Array.isArray(objectDiag)">
                    <div class="diagnostic__group" v-for="block in getDiag" :key="block.block_name" :class="{ hasItems: block.cnt }">
                        <div class="diagnostic__groupHeader" @click.prevent="showItems">
                            <div class="diagnostic__groupTitle">
                                <a href="#" class="diagnostic__groupName">{{ block.block_name }}</a> <span class="diagnostic__groupCnt" v-if="block.cnt">{{ block.cnt }}</span>
                            </div>
                            <div class="diagnostic__groupTime">{{ new Date(block.tm * 1000).toLocaleString() }}</div>
                        </div>
                        <div class="diagnostic__groupItems" v-if="block.cnt">
                            <div class="diagnostic__groupNameRus" v-if="block.block_name_ru">{{ block.block_name_ru }}</div>
                            <div class="diagnostic__groupItem" v-for="value in block.val" :key="value.value">
                                <span class="diagnostic__groupItemName">{{ value.value }} </span>
                                <span class="diagnostic__groupItemStatus" v-if="value.flag">[{{ value.flag }}]</span>
                                <span class="diagnostic__groupItemDescr">{{ value.long_name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="diagnostic__error" v-if="!Array.isArray(objectDiag)">
                    {{ objectDiag }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// Хелперы
import { Base64 } from 'js-base64'

export default {
    name: 'DispObjectDiag',
    data() {
        return {
            objectDiag: null
        }
    },
    props: {
        objectID: String,
        objectName: String
    },
    computed: {
        ...mapGetters(['getSocket', 'getDiag'])
    },
    methods: {

        ...mapActions(['addDiag']),
        
        showModal($event, show) {
            if(show) {
                document.getElementById($event.target.dataset.modal).classList.add('shown')
            } else {
                document.getElementById($event.target.dataset.modal).classList.remove('shown')
            }
        },

        requestDiagnostic(objectID) {
            if(this.getSocket.readyState == 1) {
                let cmd = 'dj' + Base64.encode(JSON.stringify({ id: Number(objectID) }))
                this.getSocket.send(cmd)
            } else {
                console.log('Нет связи с сервером')
            }
        },

        showItems(event) {
            if(event.currentTarget.parentNode.classList.contains('hasItems')) {
                event.currentTarget.parentNode.classList.toggle('shown')
            }
        },
    },
    mounted() {
        this.requestDiagnostic(this.objectID)
    }, 
    watch: { 
        getDiag(blocks) {
            if(Array.isArray(blocks)) {
                if(blocks.length == 0) {
                    this.objectDiag = 'Для выбранного объекта нет результатов диагностики.'
                } else {
                    this.objectDiag = blocks
                }
            } else if(blocks) {
                if('error' in blocks) {
                    this.objectDiag = 'Ошибка получения результатов диагностики!'
                }
            }
        }
    }
}
</script>
