<template>
    <div class="dispObject" :class="{ dispObject_passive: getObject.data.online.value == 0 || getObject.data.online.value == 1 }">

        <DispObjectTrack 
            @requestTrack="requestTrack" 
            :trackProcess="trackProcess"
            :objectName="getObject.data.name"
            />

        <DispObjectDiag
            v-if="getObject" 
            :objectID="objectID"
            :objectName="getObject.data.name"
        />
        <div class="dispObject__details modal" :class="{ shown: showDetails }" id="objectDetails">
            <div class="modal__wrapper modal__wrapper_full">
                <span class="modal__close" href="#" @click="showModal($event, false)" data-modal="objectDetails"></span>
                <div class="modal__header">{{ getObject.data.name }}</div>
                <div class="modal__descr">
                    <p>Статус: {{ getObject.data.online.descr }}</p>
                    <p>Последнее изменение: {{ showActiveDate(getObject.point.n.Ntm) }}</p>
                </div>
                <ul class="modal__body dispObject__detailsList sensors">
                    <li v-for="sensor in getObject.sensors" :key="sensor.id" class="sensors__item">
                        <span class="sensors__itemName">{{ sensor.name }}:</span>
                        <span class="sensors__itemValue">{{ sensor.value }} {{ sensor.unit }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div id="dispObject__map" class="dispObject__map">
            <a href="#" class="dispObject__btn dispObject__btn_diag" @click.prevent="showModal($event, true)" data-modal="objectDiag"></a>
            <a href="#" class="dispObject__btn trackFormControl" @click.prevent="showModal($event, true)" data-modal="objectTrack"></a>
            <a href="#" class="dispObject__btn trackFormReset" v-show="objectTrack" @click.prevent="resetTrack()">&#10006;</a>
        </div>
        <div class="dispObject__control">
            <router-link class="dispObject__controlLink" to="/">К списку</router-link>
            <a href="#" class="dispObject__controlLink" @click.prevent="showModal($event, true)" data-modal="objectDetails">Свойства</a>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import DispObjectDiag from '@/components/DispObjectDiag'
import DispObjectTrack from '@/components/DispObjectTrack'
// Карты
import Leaflet from 'leaflet'
import 'leaflet-rotatedmarker'
// Хелперы
import { Base64 } from 'js-base64'
export default {
    name: 'dispObject',
    data() {
        return {
            showDetails: false,
            trackProcess: false,
            trackShowed: false,
            objectTrack: false,
            objectTrackBegin: null,
            objectTrackEnd: null,
            objectDiag: null,
            map: null,
            objectID: ''
        }
    },
    components: {
        DispObjectDiag,
        DispObjectTrack
    },
    computed: {
        ...mapGetters(['getSocket', 'getObject', 'getPoints', 'getTrack', 'getDiag'])
    },
    methods: {
        
        ...mapActions(['addObject', 'addPoints', 'addPoint']),
        showModal($event, show) {
            if(show) {
                document.getElementById($event.target.dataset.modal).classList.add('shown')
            } else {
                document.getElementById($event.target.dataset.modal).classList.remove('shown')
            }
        },
        showActiveDate(timestamp) {
            let date = new Date(timestamp * 1000)
            return date.toLocaleString()
        },
        /**
         * Отправляет на сервер запрос для получения точек марштура
         * 
         * @param dates: Array, массив вида [ timestamp_начала_маршрута, timestamp_конца_маршрута ]
         */
        requestTrack(dates) {
            this.objectTrackStart = dates[0]
            this.objectTrackEnd = dates[1]
            this.getSocket.send('ps' + Base64.encode(JSON.stringify({ id: this.objectID, begin_date: this.objectTrackStart, end_date: this.objectTrackEnd, simplify: 0 })))
            this.trackProcess = true
            console.log( 'Запрос маршрута: ', new Date().toLocaleString() )
        },
        /**
         * Рисует на карте маршрут, соединяя последовательно точки из массива
         */
        drawTrack() {
            this.resetTrack()
            this.objectTrack = Leaflet.polyline(this.getTrack, {color: 'red'}).addTo(this.map);
            this.map.fitBounds(this.objectTrack.getBounds());
            document.getElementById('objectTrack').classList.remove('shown')
            console.log( 'Маршрут построен: ', new Date().toLocaleString() )
        },
        /**
         * @param map: Object; объект карты Leaflet
         * @param track: Array; массив точек маршрута
         */
        showTrack(map, points) {
            this.objectTrack = Leaflet.polyline(points, {color: 'red'}).addTo(map);
            map.fitBounds(this.objectTrack.getBounds());
        },
        resetTrack() {
            if(this.objectTrack) {
                this.objectTrack.remove()
                this.objectTrack = false
                this.map.panTo([
                    this.getObject.point._n.Lat,
                    this.getObject.point._n.Lon
                ])
            }
        }
    },
    mounted() {
        
        const _this = this
        this.addObject(this.objectID)
        this.map = Leaflet.map('dispObject__map', {
            center: [this.getObject.point.n.Lat, this.getObject.point.n.Lon],
            zoomControl: false,
            zoom: window.localStorage.getItem('MAP_ZOOM_LVL') || 18,
            minZoom: 5,
            maxZoom: 18,
        });
        this.map.on('zoomend', function() {
            localStorage.setItem('MAP_ZOOM_LVL', this._zoom)
        })
        Leaflet.control.zoom({ position: 'bottomright' }).addTo(this.map);
        const dispIcon = Leaflet.icon({
            iconRetinaUrl: require('../assets/images/icons/marker-map-30-53.svg'),
            iconUrl: require('../assets/images/icons/marker-map-30-53.svg'),
            iconSize: [24, 42], // Размер иконки маркера
            iconAnchor: [12, 21], // Сдвиг иконки маркера относительно lat/lon маркера
            popupAnchor: [0, -20],
        });
        // https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png
        // https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=c4f3906250f54518ae2a20c3e49d86fa
        Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            noWrap: true
        }).addTo(this.map);
        this.map.createPane("nami").style.zIndex = "200";
        Leaflet.tileLayer("https://dev.shatl-t.ru/Tiles/{z}/{x}/{y}.png", {
            pane: "nami",
            errorTileUrl: 'https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=c4f3906250f54518ae2a20c3e49d86fa'
        }).addTo(this.map);
        this.marker = Leaflet.marker([
            this.getObject.point.n.Lat,
            this.getObject.point.n.Lon
        ], {
            icon: dispIcon,
            rotationAngle: this.getObject.point.n.Dir, // Угол поворота маркера
            rotationOrigin: 'center' // Координаты оси вращения маркера (1\2 от ширины и высоты в iconSize)
        })
        this.marker.addTo(this.map);
        this.marker.bindPopup(`
            <span class="leaflet-popup-title">${this.getObject.data.name}</span>
            <span class="leaflet-popup-time">${this.showActiveDate(this.getObject.point.n.Ntm)}</span>
            <span class="leaflet-popup-spead">Скорость: ${this.getObject.point.n.Spd}</span>
        `, {
            direction: 'top',
            permanent: false
        })
        this.updateObject = setInterval(function() {
            _this.addObject(_this.objectID)
            _this.marker
                .setLatLng(new Leaflet.LatLng(
                    _this.getObject.point.n.Lat,
                    _this.getObject.point.n.Lon
                ))
                .setRotationAngle(_this.getObject.point.n.Dir)
                .bindPopup(`
                    <span class="leaflet-popup-title">${_this.getObject.data.name}</span>
                    <span class="leaflet-popup-time">${_this.showActiveDate(_this.getObject.point.n.Ntm)}</span>
                    <span class="leaflet-popup-spead">Скорость: ${_this.getObject.point.n.Spd}</span>
                `)

            if(!_this.objectTrack) {

                let mapBounds = _this.map.getBounds()

                if (!mapBounds.contains([_this.getObject.point._n.Lat, _this.getObject.point._n.Lon])) {
                    _this.map.panTo([
                        _this.getObject.point._n.Lat,
                        _this.getObject.point._n.Lon
                    ])
                }
            }
            
        }, 1000)

        this.objectID = this.$route.params.id
    },
    watch: { 
        getDiag(blocks) {
            if(Array.isArray(blocks)) {
                if(blocks.length == 0) {
                    this.objectDiag = 'Для выбранного объекта нет результатов диагностики.'
                } else {
                    this.objectDiag = blocks
                }
            } else if(blocks) {
                if('error' in blocks) {
                    this.objectDiag = 'У Вас нет доступа к результатам диагностики этого объекта.'
                }
            }
        },
        getTrack(points) {
            let pointsCnt = points.length
            if(pointsCnt == 0) {
                alert('За указанный период автомобиль не был в движении')
            } else {
                this.drawTrack(points)
            }
            this.trackProcess = false
        }
    },
    beforeDestroy () {
        clearInterval(this.updateObject)
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/views/car';
</style>