<template>
    <div class="trackForm modal" id="objectTrack">
        <div class="modal__wrapper">
            <h3 class="modal__header">Введите дату и время для отображения маршрута поездки<br><strong>{{ objectName }}</strong></h3>
            <span class="modal__close" @click="showModal($event, false)" data-modal="objectTrack"></span>
            <div class="modal__body">
                <div class="trackForm__fields">
                    <div class="trackForm__group">
                        <label for="trackFormBegin" class="trackForm__label">Старт:</label>

                        <Datepicker 
                            v-if="datesReady" 
                            readonly="readonly"
                            firstDayOfWeek="1"
                            name="trackFormStartDate" 
                            @input="setTrackStart($event)"
                            v-model="calendarStart"
                        />

                    </div>
                    <div class="trackForm__group">
                        <label for="trackFormEnd" class="trackForm__label">Финиш:</label>

                        <Datepicker 
                            v-if="datesReady" 
                            readonly="readonly"
                            firstDayOfWeek="1"
                            name="trackFormEndDate" 
                            @input="setTrackEnd($event)"
                            v-model="calendarEnd"
                        />

                    </div>

                    <div class="trackForm__quickMenu">

                        <!-- <a href="#" class="trackForm__quickMenuLink" 
                            @click.prevent="
                                setCalendarStart(weekBefore, 'toString')
                                setCalendarEnd(todayEnd, 'toString')
                                trackFormSubmit()
                            "
                        >Неделя</a> -->

                        <a href="#" class="trackForm__quickMenuLink" 
                            @click.prevent="
                                setCalendarStart(twoDaysBeforeStart, 'toString')
                                setCalendarEnd(twoDaysBeforeEnd, 'toString')
                                trackFormSubmit()
                            "
                        >Позавчера</a>

                        <a href="#" class="trackForm__quickMenuLink" 
                            @click.prevent="
                                setCalendarStart(yesterdayStart, 'toString')
                                setCalendarEnd(yesterdayEnd, 'toString')
                                trackFormSubmit()
                            "
                        >Вчера</a>

                        <a href="#" class="trackForm__quickMenuLink" 
                            @click.prevent="
                                setCalendarStart(todayStart, 'toString')
                                setCalendarEnd(todayEnd, 'toString')
                                trackFormSubmit()
                            "
                        >Сегодня</a>

                    </div>

                </div>
            </div>
            <a href="#" class="trackForm__submit active" @click.prevent="trackFormSubmit">
                <svg class="trackForm__submitSpinner" viewBox="0 0 50 50" id="trackFormSubmitSpinner" :class="{ shown: trackProcess }">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
                <span class="trackForm__submitText" id="trackFormSubmitText" :class="{ shown: !trackProcess }">Показать</span>
            </a>
        </div>
    </div>
</template>

<script>

/**
 * 
 * Методы 'setCalendarStart' и 'setCalendarEnd' изменяют дату в календаре 
 * при нажатии кнопок 'вчера' или 'сегодня', после чего срабатывает событие календаря 'input', 
 * вызывающее изменение свойств компонента 'trackStart' и 'trackEnd'
 * 
 */

import Datepicker from '@/components/NAMI_DTPicker'

import { mapGetters } from 'vuex'

export default {
    name: 'DispObjectTrack',
    data() {
        return {
            todayStart: null,
            todayEnd: null,
            yesterdayStart: null,
            yesterdayEnd: null,
            twoDaysBeforeStart: null,
            twoDaysBeforeEnd: null,

            calendarStart: null,
            calendarEnd: null,

            trackStart: null,
            trackEnd: null,
            
            datesReady: false
        }
    },
    props: {
        trackProcess: Boolean,
        objectName: String
    },
    components: {
        Datepicker
    },
    computed: {
        ...mapGetters(['getTrackCnt', 'getTrack'])
    },
    methods: {

        // Устанавливает дату начала в календарь
        setCalendarStart(date, mode) {
            this.calendarStart = this.dateBuilder(date, mode)
        },

        // Устанавливает дату конца в календарь
        setCalendarEnd(date, mode) {
            this.calendarEnd = this.dateBuilder(date, mode)
        },

        // Устанавливает дату начала марштрута
        setTrackStart(date) {
            this.trackStart = this.dateBuilder(date)
        },

        // Устанавливает дату конца марштрута
        setTrackEnd(date) {
            this.trackEnd = this.dateBuilder(date)
        },

        createDatas() {

            let today = new Date()

            this.todayStart         = today.setHours(0, 0, 0),                                 // Сегодня, 00:00
            this.todayEnd           = this.todayStart + ( ( 60 * 60 * 23 + 60 * 59 ) * 1000)   // Сегодня, 23:59
            this.yesterdayStart     = today - 60 * 60 * 24 * 1000,                             // Вчера, 00:00
            this.yesterdayEnd       = today - 60 * 1000                                        // Вчера, 23:59
            this.twoDaysBeforeStart = today - ((60 * 60 * 24) * 2) * 1000                      // Позавчера, 00:00
            this.twoDaysBeforeEnd   = today - ((60 * 60 * 24) + 60 ) * 1000                    // Позавчера, 23:59

            // this.weekBefore      = today - 60 * 60 * 24 * 6 * 1000                          // Неделю назад, 00:00
        },

        /**
         * Переводит таймштамп в дату для календаря или обратно
         * 
         * @param date: String/Number, дата из календаря или "таймштамп"
         * @param mode: String, toString - для перевода "таймштампа" в дату для календаря
         */
        dateBuilder(date, mode = false) {
            let formatDate = null
            if(mode == 'toString') {
                let d       = new Date(date),
                    year    = d.getFullYear(),
                    month   = ('0' + (Number(d.getMonth()) + 1)).slice(-2),
                    day     = ('0' + d.getDate()).slice(-2),
                    hours   = ('0' + d.getHours()).slice(-2),
                    minutes = ('0' + d.getMinutes()).slice(-2)
                formatDate  = String(`${year}-${month}-${day} ${hours}:${minutes}`)
            } else {
                formatDate = Number(new Date(date.replace(' ', 'T')).getTime()) // Приводим строку к ISO 8601, берём "таймштамп"
            }
            return formatDate
        },

        // Действие по нажатию кнопки формы маршрута "ПОКАЗАТЬ"
        trackFormSubmit() {

            if(!this.trackProcess) {

                const _this = this

                setTimeout(function() {

                    let start = Math.round(_this.trackStart / 1000),
                        end = Math.round(_this.trackEnd / 1000)

                    if(start && end) {
                        if(start < end) {
                            if(end - start < 86400) {
                                _this.$emit('requestTrack', [ start, end ])
                            } else {
                                alert('Максимальный интервал для запроса маршрута - 24 часа')
                            }
                        } else {
                            alert('Дата начала маршрута должна быть раньше даты конца')
                        } 
                    } else {
                        alert('Укажите даты начала и конца маршрута')
                    }
                }, 200)

            }
        },

        showModal($event, show) {
            if(show) {
                document.getElementById($event.target.dataset.modal).classList.add('shown')
            } else {
                document.getElementById($event.target.dataset.modal).classList.remove('shown')
            }
        }
    },
    mounted() {

        this.createDatas()

        this.datesReady = true

        this.trackStart = Math.round(this.todayStart)
        this.trackEnd = Math.round(this.todayEnd)

        this.setCalendarStart(this.todayStart, 'toString')
        this.setCalendarEnd(this.todayEnd, 'toString')

    }
}
</script>